import React, {useState} from 'react'

import Circle from './Circle'
import Welcome from './Welcome'
import Menu from './Menu'

const App = () => {
  const [ showWelcome, setShowWelcome ] = useState(true)
  const [ showColors, setShowColors ] = useState(false)
  const [ type, setType ] = useState('Rays')

  return <div style={{background: showColors ? 'yellow' : 'white'}}>

    {showWelcome && <Welcome setShowWelcome={setShowWelcome} />}

    <Menu
      setShowColors={setShowColors}
      showColors={showColors}
      setType={setType}
      type={type}
    />

    <Circle type={type} color={showColors ? 'hsl(240, 100%, 50%)' : 'black'} />
    <Circle type={type} color={showColors ? 'hsl(0, 100%, 50%)'   : 'black'} />
  </div>
}

export default App