import React, {useRef, useEffect} from 'react'
import Impetus from 'impetus'

import Ray from './Ray'
import Ring from './Ring'

const RAYS = 200
const RINGS = 100

const windowWidth = document.documentElement.clientWidth
const windowHeight = document.documentElement.clientHeight
const center = {
  x: windowWidth / 2,
  y: windowHeight / 2,
}

const diameter = Math.min(windowWidth, windowHeight)
const radius = diameter / 2

const Circle = ({ color, type }) => {

  const ref = useRef()

  useEffect( () => {
    new Impetus({
      source: ref.current,
      friction: 1,
      multiplier: .2,
      boundX: [0 - radius, windowWidth - radius],
      boundY: [0 - radius, windowHeight - radius],
      initialValues: [center.x - radius, center.y - radius],
      update: (x, y) => ref.current.style.cssText = `transform: translate(${x}px, ${y}px);`,
    })
  }, []) // prevent repositioning after menu click (didn't figure out why exactly)

  return <svg
    ref={ref}
    width={diameter}
    height={diameter}
    viewBox={`0 0 ${diameter} ${diameter}`}
    xmlns="http://www.w3.org/2000/svg"
    style={{transform: `translate(${center.x - radius}px, ${center.y - radius}px)`}}
  >

    {
      type === 'Rays' ?
        [...Array(RAYS)].map( (ray, index) => (
          <Ray
            key={index}
            index={index}
            color={color}
            radius={radius}
            rays={RAYS}
            />
        ))
      :
        [...Array(RINGS)].map( (ring, index) => (
          <Ring
            key={index}
            index={index}
            color={color}
            radius={radius}
            rings={RINGS}
            />
        ))
    }
  </svg>
}

export default Circle