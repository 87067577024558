import React from 'react'

const Ray = ({rays, index, radius, color}) => {

  const angle = (2 * Math.PI) / rays

  const getX = angle => Math.cos(angle) * radius
  const getY = angle => Math.sin(angle) * radius

  const origin = `${radius}, ${radius}`

  const angle1 = angle * index
  const angle2 = angle * (index + .5)
  
  const point1 = {
    x: getX(angle1) + radius,
    y: getY(angle1) + radius,
  }
  const point2 = {
    x: getX(angle2) + radius,
    y: getY(angle2) + radius,
  }
  
  return <>
    <path 
      d={`
        M${origin}
        L${point1.x}, ${point1.y}
        L${point2.x}, ${point2.y}
        L${origin}
        Z
      `}
      style={{fill: color}}
    />
  </>

}

export default Ray