import React from 'react'

const Menu = ({showColors, setShowColors, type, setType}) => {

  return <div className="menu-wrapper">
    <span className="menu-option" onClick={() => setShowColors(!showColors)}>{showColors ? 'Color' : 'No color'}</span>
    <span className="menu-option" onClick={() => setType(type === 'Rays' ? 'Rings' : 'Rays')}>{type}</span>
  </div>
}

export default Menu