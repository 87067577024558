import React from 'react'

const Ring = ({index, radius, color, rings}) => {
  
  const ringRadius = index * (radius / rings)
  const strokeWidth = (radius / rings) / 2

  return <circle
    cx={radius}
    cy={radius}
    r={ringRadius}
    style={{fill: 'none', stroke: color, strokeWidth }}
  />
}

export default Ring