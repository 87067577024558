import React from 'react'

const Welcome = ({setShowWelcome}) => {

  return <div style={styles.background} onClick={() => setShowWelcome(false)}>

    <div style={styles.window} onClick={e => e.stopPropagation()}>

      <h1 style={styles.h1}>MOIRER</h1>

      <p style={styles.warning}>WARNING: MAY CAUSE SEIZURES</p>
      
      <p>
        Instructions: slowly drag and fling the shapes around the screen
        {/* Some browsers may freeze */}
      </p>

      <p>
        <a style={styles.link} href="https://en.wikipedia.org/wiki/Moir%C3%A9_pattern" target="_blank" rel="noopener noreferrer">Moire patterns</a>
        {' · '}
        <a style={styles.link} href="https://sampiercelolla.com/" target="_blank" rel="noopener noreferrer">Sam Pierce Lolla</a>
      </p>

      <button style={styles.button} onClick={() => setShowWelcome(false)}>Go</button>
    </div>

  </div>
}

const styles = {
  background: {
    background: 'hsla(0, 0%, 0%, .6)',
    padding: '3rem 0',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    fontFamily: 'sans-serif',
  },
  window: {
    background: 'white',
    padding: '2rem',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'center',
  },
  h1: {
    margin: '0 0 2rem',
    fontSize: '5rem',
    fontWeight: '900',
  },
  warning: {
    fontWeight: '900',
    color: 'red',
  },
  link: {
    color: 'black',
  },
  button: {
    width: '100%',
    padding: '1rem',
    background: 'black',
    color: 'white',
    fontWeight: 900,
    border: 'none',
    fontSize: '1rem',
    fontFamily: 'sans-serif',
    margin: '2rem 0 0',
    cursor: 'pointer',
  },
}

export default Welcome